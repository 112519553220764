import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useMedia } from 'react-use';
import clsx from 'clsx';
import { Fzf } from 'fzf';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { Helmet } from 'react-helmet';
import Layout from '../../../components/Layout';
import Typography from '../../../components/Typography';
import Grid from '../../../components/Grid';
import Input from '../../../components/Input';
import camelize from '../../../utils/camelize';
import Card from '../../../components/Card';
import CardBody from '../../../components/CardBody';
import Icon from '../../../components/Icon';
import Breadcrumbs from '../../../components/Breadcrumbs';
import getSchemaBreadcrumbs from '../../../utils/getSchemaBreadcrumbs';

const localizations = [
  {
    locale: 'nl-NL',
    slug: '/nl/over-ons/partners/',
  },
];

const Partners = ({ data, location }) => {
  const [activeFilters, setActiveFilters] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { strapiPage, site } = data;
  const { blocks, seo_title: seoTitle, seo_description: seoDescription } = strapiPage || {};
  const [block] = blocks || [];
  const { contents = [], pre_title: preTitle, title } = block || {};
  const isMobile = useMedia('(max-width: 767px)');
  const isTablet = useMedia('(min-width: 768px) and (max-width: 991px)');

  const formattedAddons = contents.reduce(
    (acc, addon) => [...acc, { ...addon, labels: addon.cta_label_1.split(',').filter(label => label !== '') }],
    [],
  );

  const categories = contents.reduce((acc, item) => {
    const labels = item.cta_label_1.split(',');

    const formattedLabels = labels.reduce(
      (labelAcc, label) => ({
        ...labelAcc,
        [camelize(label)]: {
          label,
          count: acc?.[camelize(label)] ? acc?.[camelize(label)].count + 1 : 1,
        },
      }),
      {},
    );

    return {
      ...acc,
      ...formattedLabels,
    };
  }, {});

  const handleClickFilter = categoryId => () => {
    setActiveFilters([categoryId]);
  };

  const handleClickAllFilter = () => {
    setActiveFilters([]);
  };

  const handleChangeCategory = event => setActiveFilters(event.target.value ? [event.target.value] : []);

  const filterAddons = addon =>
    activeFilters.length > 0 ? !!addon.labels.some(label => activeFilters.includes(camelize(label))) : true;

  const fzf = new Fzf(formattedAddons, {
    selector: item => item.title,
    cache: true,
    normalize: true,
  });
  const searchedItems = fzf.find(searchTerm).map(entry => entry.item);

  return (
    <Layout location={location} locale="en" localizations={localizations} slug="/about-us/partners/">
      <Helmet
        title={seoTitle}
        meta={[
          {
            name: 'description',
            content: seoDescription,
          },
        ]}
      >
        <html lang="en" />
        <script type="application/ld+json">
          {`
            [
              ${getSchemaBreadcrumbs(location)}
              {
                "@context": "http://schema.org",
                "@type": "Organization",
                "name": "${site?.siteMetadata?.siteName}",
                "logo": "${site?.siteMetadata?.logo}",
                "@id": "${site?.siteMetadata?.company?.kvk}",
                "url": "${site?.siteMetadata?.siteUrl}",
                "foundingDate": "${site?.siteMetadata?.company?.foundingDate}",
                "founders": ["${site?.siteMetadata?.company?.founders.join('", "')}"],
                "contactPoint": {
                  "@context": "http://schema.org",
                  "@type": "contactPoint",
                  "contactType": "customer service",
                  "telephone": "${site?.siteMetadata?.company?.phoneNumber}",
                  "email": "${site?.siteMetadata?.company?.email}"
                },
                "sameAs": ["${site?.siteMetadata?.twitter}", "${site?.siteMetadata?.facebook}"],
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "${site?.siteMetadata?.address?.streetAddress}",
                  "addressLocality": "${site?.siteMetadata?.address?.locality}",
                  "addressRegion": "${site?.siteMetadata?.address?.region}",
                  "postalCode": "${site?.siteMetadata?.address?.postalCode}",
                  "addressCountry": "${site?.siteMetadata?.address?.country}"
                }
              }
            ]
          `}
        </script>
      </Helmet>
      <Grid container className="pb-96 pt-16 pt-md-24">
        <Breadcrumbs location={location} locale="en" />
        <Typography
          variant={isMobile || isTablet ? 'h4' : 'h3'}
          weight="semi-bold"
          component="h1"
          className="mt-40 mb-12"
        >
          {title}
        </Typography>
        <Typography variant="body-medium" color="gray-af-110" className="mb-40">
          {preTitle}
        </Typography>
        <Grid row>
          <Grid column xs={12} lg={3}>
            <Grid row className="mb-32 mb-lg-0" spacing={12}>
              <Grid column xs={12} md={8} lg={12}>
                <div className="input-group addon-search mb-12 mb-md-0 mb-lg-32">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      <Icon icon={faSearch} fixedWidth />
                    </span>
                  </div>
                  <Input
                    name="search"
                    className="addon-search"
                    showLabel={false}
                    onChange={event => setSearchTerm(event.target.value)}
                    placeholder="Find a partner"
                    id="search-addons"
                  />
                </div>
              </Grid>
              <Grid column xs={12} md={4} lg={12}>
                {(isMobile || isTablet) && (
                  <select className="form-control" onChange={handleChangeCategory}>
                    <option value="">Alle categorieën ({formattedAddons.length})</option>
                    {Object.keys(categories).map(categoryId => {
                      const category = categories[categoryId];
                      return (
                        <option key={categoryId} value={categoryId}>
                          {category.label} ({category.count})
                        </option>
                      );
                    })}
                  </select>
                )}
                {!isMobile && !isTablet && (
                  <nav className="nav nav-filters flex-column nav-pills">
                    <button
                      type="button"
                      className={clsx('nav-link', {
                        active: activeFilters.length === 0,
                      })}
                      onClick={handleClickAllFilter}
                    >
                      <span>All categories</span>
                      <span className="count">{formattedAddons.length}</span>
                    </button>
                    {Object.keys(categories).map(categoryId => {
                      const category = categories[categoryId];
                      return (
                        <button
                          key={categoryId}
                          type="button"
                          className={clsx('nav-link', {
                            active: activeFilters.includes(categoryId),
                          })}
                          onClick={handleClickFilter(camelize(category.label))}
                        >
                          <span>{category.label}</span>
                          <span className="count">{category.count}</span>
                        </button>
                      );
                    })}
                  </nav>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid column xs={12} lg={9}>
            <Grid row spacing={isMobile ? 24 : 30}>
              {(searchTerm ? searchedItems : formattedAddons).filter(filterAddons).map(addOn => (
                <Grid column xs={12} md={6} lg={4} key={addOn.id}>
                  <Card
                    className="h-100 addon-card"
                    component={addOn.cta_url_1 ? 'a' : 'div'}
                    {...(addOn.cta_url_1 ? { href: addOn.cta_url_1, target: '_BLANK' } : {})}
                  >
                    <CardBody className="d-flex flex-column align-items-start px-32 pt-32 pb-40">
                      <img
                        src={`${addOn?.images?.[0]?.url}?h=64`}
                        height="32"
                        alt={addOn?.images?.[0]?.alternativeText}
                        className="mb-32 object-fit-contain"
                      />
                      <Typography
                        variant="body-medium"
                        component="h2"
                        weight="semi-bold"
                        color="gray-af-120"
                        className="mb-8"
                      >
                        {addOn.title}
                      </Typography>
                      {addOn.body && (
                        <Typography variant="body-small" color="gray-af-110" className="mb-auto">
                          {addOn.body}
                        </Typography>
                      )}
                      <div className="mt-auto pt-24">
                        <div className="d-flex flex-wrap m-n4">
                          {addOn.labels.map(label => (
                            <div className="p-4" key={camelize(label)}>
                              <div className="addon-chip">{label}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export const partnersQuery = graphql`
  query {
    site {
      siteMetadata {
        logo
        siteUrl
        siteName
        twitter
        facebook
        company {
          foundingDate
          founders
          phoneNumber
          email
        }
        address {
          streetAddress
          locality
          region
          postalCode
          country
        }
      }
    }
    strapiPage(slug: { eq: "about-us/partners/" }) {
      id
      title
      seo_title
      seo_description
      blocks {
        title
        pre_title
        contents {
          id
          images {
            name
            width
            height
            alternativeText
            formats {
              large {
                url
              }
              medium {
                url
              }
              small {
                url
              }
              thumbnail {
                url
              }
            }
            url
          }
          body
          title
          cta_label_1
          cta_label_2
          cta_url_1
        }
      }
    }
  }
`;

Partners.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

Partners.defaultProps = {
  data: undefined,
  location: undefined,
};

export default Partners;
